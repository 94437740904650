// index.tsx

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import TopImageComponent from './components/TopImageComponent'; // TopImageComponentをインポート
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <TopImageComponent /> {/* TopImageComponentをここに追加 */}
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
